"use strict";

var salOptions = {
  rootMargin: '0% 100%',
  threshold: 0.2,
  animateClassName: 'sal-animate',
  disabledClassName: 'sal-disabled',
  enterEventName: 'sal:in',
  exitEventName: 'sal:out',
  selector: '[data-sal]',
  once: true,
  disabled: false
};
module.exports = {
  salOptions: salOptions
};